import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge } from '@gameficame/ui-kit/src/components';
export const statusColors = {
    ACTIVE: 'green',
    PROCESSING: 'yellow',
    STORE_NOT_REGISTERED: 'yellow',
    FAILED: 'red',
    DUPLICATE: 'red',
    INVALID_CODE: 'red',
};
export function BadgeStatus({ status }) {
    const { t } = useTranslation();
    const statusTexts = {
        ACTIVE: t('badge.success'),
        PROCESSING: t('badge.processing'),
        FAILED: t('badge.error'),
        STORE_NOT_REGISTERED: t('badge.storeNotRegistered'),
        DUPLICATE: t('badge.error'),
        INVALID_CODE: t('badge.error'),
    };
    const color = statusColors[status] || 'red';
    const textKey = statusTexts[status] || 'ERRO';
    return (_jsx(Badge, Object.assign({ "data-testid": "badge-status", badgeColor: color }, { children: t(textKey) })));
}
