import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { PrivateRouteContainer } from '~/layouts/containers/PrivateRouteContainer';
import { useInitialData } from '~/layouts/hooks/useInitialData';
function RouteWrapper({ component: Component, isProtectedLayout = false, }) {
    const location = useLocation();
    const { data } = useInitialData();
    const { t } = useTranslation();
    const pagesTitle = {
        '/admin': t('pagesTitle.admin'),
        '/admin/forgot-password': t('pagesTitle.admin.forgotPassword'),
        '/admin/panel': t('pagesTitle.admin.panel'),
        '/admin/item-shop': t('pagesTitle.admin.itemShop'),
        '/admin/item-shop/new-item': t('pagesTitle.admin.itemShop.newItem'),
        '/admin/item-shop/edit-item/:id': t('pagesTitle.admin.itemShop.editItem'),
        '/admin/users': t('pagesTitle.admin.users'),
        '/admin/users/:id': t('pagesTitle.admin.users.profile'),
        '/admin/users/edit/:id': t('pagesTitle.admin.users.edit'),
        '/admin/daily-rewards': t('pagesTitle.admin.dailyRewards'),
        '/admin/daily-rewards/new': t('pagesTitle.admin.dailyRewards.new'),
        '/admin/new-password': t('pagesTitle.admin.newPassword'),
        '/admin/reset-password': t('pagesTitle.admin.resetPassword'),
        '/admin/stores': t('pagesTitle.admin.stores'),
        '/admin/stores/new-store': t('pagesTitle.admin.stores.newStore'),
        '/admin/stores/:id': t('pagesTitle.admin.stores.editStore'),
        '/admin/new-prize': t('pagesTitle.admin.newPrize'),
        '/admin/vouchers': t('pagesTitle.admin.vouchers'),
        '/admin/vouchers/new-voucher': t('pagesTitle.admin.vouchers.newVoucher'),
        '/admin/vouchers/add-voucher/:id': t('pagesTitle.admin.vouchers.addVoucher'),
        '/admin/add-points': t('pagesTitle.admin.addPoints'),
        '/admin/exchange': t('pagesTitle.admin.exchange'),
        '/admin/campaigns': t('pagesTitle.admin.campaigns'),
        '/admin/campaigns/new-campaign': t('pagesTitle.admin.campaigns.newCampaign'),
        '/admin/campaigns/edit-campaign/:id': t('pagesTitle.admin.campaigns.editCampaign'),
        '/admin/theme': t('pagesTitle.admin.theme'),
        '/admin/raffle': t('pagesTitle.admin.raffle'),
        '/admin/new-raffle': t('pagesTitle.admin.newRaffle'),
        '/admin/raffle/check-winner': t('pagesTitle.admin.raffle.checkWinner'),
        '/admin/raffle/edit/:id': t('pagesTitle.admin.raffle.edit'),
        '/admin/member': t('pagesTitle.admin.member'),
        '/admin/member/new-member': t('pagesTitle.admin.member.newMember'),
        '/admin/member/edit/:id': t('pagesTitle.admin.member.edit'),
        '/admin/item-stock/:id': t('pagesTitle.admin.itemStock'),
        '/admin/nfes': t('pagesTitle.admin.nfes'),
        '/admin/nfes/new-nfe': t('pagesTitle.admin.nfes.newNfe'),
    };
    const club = `${data === null || data === void 0 ? void 0 : data.account_name} | `;
    function getPageTitle(pathname) {
        const matchedRoute = Object.keys(pagesTitle).find((route) => {
            const routeRegex = new RegExp(`^${route.replace(/:\w+/g, '\\w+')}$`);
            return routeRegex.test(pathname);
        });
        return matchedRoute ? pagesTitle[matchedRoute] : '';
    }
    const title = club
        ? `${club + getPageTitle(location.pathname)}`
        : getPageTitle(location.pathname);
    useEffect(() => {
        document.title = title;
    }, [club, location]);
    if (isProtectedLayout) {
        return (_jsx(PrivateRouteContainer, Object.assign({ redirectTo: "/admin" }, { children: _jsx(Component, {}) })));
    }
    return _jsx(Component, {});
}
export default RouteWrapper;
