import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
export const SidebarBody = styled.div `
  width: ${({ isOpen }) => (isOpen ? '250px' : '80px')};
  height: 100%;
  background: ${({ theme }) => theme.colors.purple900};
  transition: width 0.3s ease;
`;
export const LinkList = styled.ul `
  position: relative;
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
  padding-bottom: 16px;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.purple900};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.grey500};
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
  }
`;
export const SidebarButton = styled(NavLink) `
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 10px;
  margin: 2px 10px;
  text-decoration: none;

  &:hover {
    background: #ffffff15;
  }
`;
export const NavBtn = styled.div `
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-radius: 10px;
  margin: 2px 10px;

  &:hover {
    background: #ffffff15;
  }
`;
export const SubMenu = styled.div `
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  height: ${({ opened, tall }) => (opened === 'true' ? tall * 45 : 0)}px;
  transition: all 0.4s ease-in-out;
`;
// active === 'true' because the console of navigator was alerting: Received `true` for non-boolean attribute `active`. If this is expected, cast the value to a string.
export const SubLink = styled(NavLink) `
  color: ${({ active, theme }) => active === 'true' ? theme.colors.pink500 : theme.colors.white};
  padding: 8px 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin: 2px 10px;
  border-radius: 10px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;

  &:hover {
    color: ${({ theme }) => theme.colors.pink500};
    transition: all 0.2s ease;
  }

  ${({ disabled }) => disabled &&
    `
    color: ${({ theme }) => theme.colors.grey500};
    cursor: default;
    pointer-events: none;
  `}
`;
export const Image = styled.img `
  width: ${({ isOpen }) => (isOpen ? '178px' : '42px')};
  height: ${({ isOpen }) => (isOpen ? '42px' : '42px')};
  cursor: pointer;
`;
export const ToggleButton = styled.button `
  background: ${({ theme }) => theme.colors.purple900};
  border: none;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 8px;

  position: absolute;
  top: 50px;
  right: -18px;
`;
