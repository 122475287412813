var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/* eslint-disable no-plusplus */
// Regex para validação de string no formato CNPJ
export const regexCNPJ = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/;
// Elimina tudo que não é dígito
function matchNumbers(value = '') {
    const match = value.toString().match(/\d/g);
    return Array.isArray(match) ? match.map(Number) : [];
}
// Cálculo validador
function validCalc(x, numbers) {
    const slice = numbers.slice(0, x);
    let factor = x - 7;
    let sum = 0;
    for (let i = x; i >= 1; i--) {
        const n = slice[x - i];
        sum += n * factor--;
        if (factor < 2)
            factor = 9;
    }
    const result = 11 - (sum % 11);
    return result > 9 ? 0 : result;
}
// Método de validação
// Referência: https://pt.wikipedia.org/wiki/Cadastro_Nacional_da_Pessoa_Jur%C3%ADdica
export function validCNPJ(value = '') {
    if (!value)
        return false;
    // Aceita receber o valor como string, número ou array com todos os dígitos
    const isString = typeof value === 'string';
    const validTypes = isString || Number.isInteger(value) || Array.isArray(value);
    // Elimina valor de tipo inválido
    if (!validTypes)
        return false;
    // Filtro inicial para entradas do tipo string
    if (isString) {
        // Teste Regex para veificar se é uma string apenas dígitos válida
        const digitsOnly = /^\d{14}$/.test(value);
        // Teste Regex para verificar se é uma string formatada válida
        const validFormat = regexCNPJ.test(value);
        // Verifica se o valor passou em ao menos 1 dos testes
        const isValid = digitsOnly || validFormat;
        // Se o formato não é válido, retorna inválido
        if (!isValid)
            return false;
    }
    // Elimina tudo que não é dígito
    const numbers = matchNumbers(value);
    // Valida a quantidade de dígitos
    if (numbers.length !== 14)
        return false;
    // Elimina inválidos com todos os dígitos iguais
    const items = [...new Set(numbers)];
    if (items.length === 1)
        return false;
    // Separa os 2 últimos dígitos verificadores
    const digits = numbers.slice(12);
    // Valida 1o. dígito verificador
    const digit0 = validCalc(12, numbers);
    if (digit0 !== digits[0])
        return false;
    // Valida 2o. dígito verificador
    const digit1 = validCalc(13, numbers);
    return digit1 === digits[1];
}
// Método de formatação
export function formatCNPJ(value = '') {
    // Verifica se o valor é válido
    // const valid = validCNPJ(value);
    // Se o valor não for válido, retorna vazio
    // if (!valid) return '';
    // Elimina tudo que não é dígito
    const numbers = matchNumbers(value);
    const text = numbers.join('');
    // Formatação do CNPJ: 99.999.999/9999-99
    const format = text.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    // Retorna o valor formatado
    return format;
}
const currentDomain = window.location.origin;
export const imageDomain = currentDomain.includes('localhost')
    ? currentDomain.replace('localhost:3000', 'gameficadev.net')
    : currentDomain;
export function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear().toString().slice(2);
    return `${day}/${month}/${year}`;
}
export function formatTime(dateString) {
    const date = new Date(dateString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
}
export const isValidCPF = (value) => {
    let cpf = value;
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
        return false;
    }
    let sum = 0;
    for (let i = 1; i <= 9; i++) {
        sum += parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
    }
    let rest = (sum * 10) % 11;
    if (rest === 10 || rest === 11)
        rest = 0;
    if (rest !== parseInt(cpf.substring(9, 10), 10)) {
        return false;
    }
    sum = 0;
    for (let i = 1; i <= 10; i++) {
        sum += parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
    }
    rest = (sum * 10) % 11;
    if (rest === 10 || rest === 11)
        rest = 0;
    if (rest !== parseInt(cpf.substring(10, 11), 10)) {
        return false;
    }
    return true;
};
export const isValidSSN = (ssn) => {
    const ssnRegex = /^(?!666|000|9\d{2})\d{3}(?!00)\d{2}(?!0000)\d{4}$/;
    return ssnRegex.test(ssn);
};
export const formatSSNorCPF = (value) => {
    const cleanValue = value.replace(/\D/g, '');
    if (cleanValue.length <= 9) {
        return cleanValue
            .replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3')
            .substring(0, 11);
    }
    return cleanValue
        .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
        .substring(0, 14);
};
export function removePunctuation(value) {
    return value.replace(/[^\d]/g, '');
}
export function validateColor(color) {
    const colorRegex = /^#([0-9A-Fa-f]{3}){1,2}$|^rgb\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3})\)$|^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(0|1|0?\.\d+)\)$|^hsl\(\d{1,3},\s*\d+%,\s*\d+%\)$|^hsla\(\d{1,3},\s*\d+%,\s*\d+%,\s*(0|1|0?\.\d+)\)$/;
    return colorRegex.test(color);
}
export const mechanicSourceTypeName = {
    GAMESTOREITEM_ADD: 'mechanicSourceTypes.refund',
    GAMESTOREITEM_REMOVE: 'mechanicSourceTypes.purchase',
    NONE_ADD: 'mechanicSourceTypes.manualAdd',
    NONE_REMOVE: 'mechanicSourceTypes.manualRemove',
    VOUCHER_ADD: 'mechanicSourceTypes.voucherRedeemed',
    NFE_ADD: 'mechanicSourceTypes.nfeRedeemed',
    LOTTERYDRAW_REMOVE: 'mechanicSourceTypes.lotteryDraw',
    MEMBERGETMEMBER_ADD: 'mechanicSourceTypes.mgm',
};
export const generateImageUrl = ({ reqImagePath, newFile }) => {
    if (newFile) {
        return URL.createObjectURL(newFile);
    }
    return reqImagePath ? imageDomain + reqImagePath : undefined;
};
export const generatePdfFile = ({ reqPdfPath, newFile }) => __awaiter(void 0, void 0, void 0, function* () {
    if (newFile) {
        return newFile;
    }
    const pdfUrl = reqPdfPath ? imageDomain + reqPdfPath : undefined;
    if (!pdfUrl) {
        return undefined;
    }
    const response = yield fetch(pdfUrl);
    const blob = yield response.blob();
    return new File([blob], 'regulation.pdf', { type: 'application/pdf' });
});
export function lightenColor(hexColor, amount) {
    if (hexColor) {
        const hex = hexColor.replace(/^#/, '');
        let r;
        let g;
        let b;
        if (hex.length === 3) {
            r = parseInt(hex[0] + hex[0], 16);
            g = parseInt(hex[1] + hex[1], 16);
            b = parseInt(hex[2] + hex[2], 16);
        }
        else {
            r = parseInt(hex.slice(0, 2), 16);
            g = parseInt(hex.slice(2, 4), 16);
            b = parseInt(hex.slice(4, 6), 16);
        }
        r = Math.min(255, Math.floor(r + (255 - r) * amount));
        g = Math.min(255, Math.floor(g + (255 - g) * amount));
        b = Math.min(255, Math.floor(b + (255 - b) * amount));
        const toHex = (color) => color.toString(16).padStart(2, '0');
        return `#${toHex(r)}${toHex(g)}${toHex(b)}`.toUpperCase();
    }
    return undefined;
}
export const formatAccessKey = (key) => {
    const trimmedKey = key.length > 54 ? key.substring(0, 54) : key;
    return trimmedKey.replace(/(\d{4})(?=\d)/g, '$1 ');
};
export const formatNumberToCurrency = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
});
export function convertMsToDaysHoursMinutesSeconds(ms) {
    const days = Math.floor(ms / 86400000);
    const hours = Math.floor((ms % 86400000) / 3600000);
    const minutes = Math.floor((ms % 3600000) / 60000);
    const seconds = Math.floor((ms % 60000) / 1000);
    const parts = [];
    if (days > 0)
        parts.push(`${days}d`);
    if (hours > 0)
        parts.push(`${hours}h`);
    if (minutes > 0)
        parts.push(`${minutes}m`);
    if (seconds > 0 || parts.length === 0)
        parts.push(`${seconds}s`);
    return parts.join(' ');
}
