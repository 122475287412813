import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { LazyLoader } from '@gameficame/ui-kit/src/components';
import Cookies from 'js-cookie';
import { GROUPS } from '~/layouts/constants';
import { refreshTokens } from '~/layouts/contexts/AuthProvider/TokenStorageService';
import { useMe } from '~/layouts/hooks/useMe';
const PrivateRouteContainer = ({ children, redirectTo, }) => {
    const location = useLocation();
    const accessToken = Cookies.get('accessToken');
    const refreshToken = Cookies.get('refreshToken');
    const { data: me, isLoading: loadingMe } = useMe();
    if (!accessToken && refreshToken) {
        refreshTokens();
    }
    // Evita que usuários EXCHANGER veja o dashboard
    if (loadingMe) {
        return _jsx(LazyLoader, {});
    }
    if ((me === null || me === void 0 ? void 0 : me.group) === GROUPS.EXCHANGER &&
        location.pathname !== '/admin/exchange') {
        return _jsx(Navigate, { to: "/admin/exchange", replace: true });
    }
    if ((!accessToken && !refreshToken) || !refreshToken) {
        return _jsx(Navigate, { to: redirectTo });
    }
    return children;
};
export default PrivateRouteContainer;
