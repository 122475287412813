export const NFE_STATUS = {
    ACTIVE: 'ACTIVE',
    STORE_NOT_REGISTERED: 'STORE_NOT_REGISTERED',
    PROCESSING: 'PROCESSING',
    FAILED: 'FAILED',
    DUPLICATE: 'DUPLICATE',
    INVALID_CODE: 'INVALID_CODE',
};
export const GROUPS = {
    ADMIN: 'ADMIN',
    PLAYER: 'PLAYER',
    EXCHANGER: 'EXCHANGER',
};
