var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable camelcase */
import React, { createContext, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CognitoUser, AuthenticationDetails, CognitoUserPool, } from 'amazon-cognito-identity-js';
import { useInitialData } from '~/layouts/hooks/useInitialData';
import { removeTokens, setTokens } from './TokenStorageService';
export const AuthContext = createContext({});
export function AuthProvider({ children }) {
    const [email, setEmail] = useState('');
    const [localUser, setLocalUser] = useState();
    const navigate = useNavigate();
    const { data: initialData, isLoading, isError } = useInitialData();
    const createCognitoUserPool = ({ admin_app_user_pool_id, admin_app_user_pool_client_id, }) => {
        return new CognitoUserPool({
            UserPoolId: admin_app_user_pool_id,
            ClientId: admin_app_user_pool_client_id,
        });
    };
    const cognitoUserPool = useMemo(() => {
        if (!isLoading && !isError && initialData) {
            return createCognitoUserPool(initialData);
        }
        return null;
    }, [initialData, isLoading, isError]);
    const signIn = ({ Username, Password, }) => __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            if (!cognitoUserPool) {
                reject(new Error('CognitoUserPool is not avaible.'));
                return;
            }
            const user = new CognitoUser({
                Username,
                Pool: cognitoUserPool,
            });
            const authDetails = new AuthenticationDetails({
                Username,
                Password,
            });
            user.authenticateUser(authDetails, {
                onSuccess: (data) => {
                    var _a, _b;
                    setEmail(Username);
                    resolve(data);
                    setTokens({
                        username: Username,
                        accessToken: data.getAccessToken().getJwtToken(),
                        refreshToken: data.getRefreshToken().getToken(),
                        gameStoreId: (_a = initialData === null || initialData === void 0 ? void 0 : initialData.game_store_id) !== null && _a !== void 0 ? _a : '',
                        accountId: (_b = initialData === null || initialData === void 0 ? void 0 : initialData.account_id) !== null && _b !== void 0 ? _b : '',
                    });
                    navigate('/admin/panel');
                },
                onFailure: (err) => {
                    reject(err);
                },
                newPasswordRequired: (data) => {
                    setLocalUser(user);
                    setEmail(Username);
                    navigate('/admin/new-password');
                    resolve(data);
                },
            });
        });
    });
    const completeFirstLogin = ({ newPassword }) => {
        return new Promise((resolve, reject) => {
            localUser === null || localUser === void 0 ? void 0 : localUser.completeNewPasswordChallenge(newPassword, {}, {
                onSuccess: (data) => {
                    var _a, _b;
                    setTokens({
                        username: email,
                        accessToken: data.getAccessToken().getJwtToken(),
                        refreshToken: data.getRefreshToken().getToken(),
                        gameStoreId: (_a = initialData === null || initialData === void 0 ? void 0 : initialData.game_store_id) !== null && _a !== void 0 ? _a : '',
                        accountId: (_b = initialData === null || initialData === void 0 ? void 0 : initialData.account_id) !== null && _b !== void 0 ? _b : '',
                    });
                    resolve(data);
                    navigate('/admin/panel');
                },
                onFailure: (err) => {
                    reject(err);
                },
            });
        });
    };
    const logout = () => __awaiter(this, void 0, void 0, function* () {
        const user = cognitoUserPool === null || cognitoUserPool === void 0 ? void 0 : cognitoUserPool.getCurrentUser();
        if (user) {
            user.signOut();
            removeTokens();
            navigate('/admin');
        }
    });
    // Send email verification code to change password
    const forgotPassword = ({ username }) => __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            if (!cognitoUserPool) {
                reject(new Error('CognitoUserPool is not avaible.'));
                return;
            }
            const user = new CognitoUser({
                Username: username,
                Pool: cognitoUserPool,
            });
            user.forgotPassword({
                onSuccess: (data) => {
                    resolve(data);
                },
                onFailure: (err) => {
                    reject(err);
                },
                inputVerificationCode: (data) => {
                    resolve(data);
                    navigate('/admin');
                },
            });
        });
    });
    // Change password using email verification code
    const resetPassword = ({ code, password, username, }) => __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            if (!cognitoUserPool) {
                reject(new Error('CognitoUserPool is not avaible.'));
                return;
            }
            const user = new CognitoUser({
                Username: username,
                Pool: cognitoUserPool,
            });
            user.confirmPassword(code, password, {
                onSuccess: (data) => {
                    resolve(data);
                    navigate('/admin');
                },
                onFailure: (err) => {
                    reject(err);
                },
            });
        });
    });
    const auth = useMemo(() => ({
        signIn,
        logout,
        completeFirstLogin,
        forgotPassword,
        resetPassword,
    }), [signIn, logout, completeFirstLogin, forgotPassword, resetPassword]);
    return _jsx(AuthContext.Provider, Object.assign({ value: auth }, { children: children }));
}
