import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Badge, Group, Text } from '@gameficame/ui-kit/src/components';
import { Tooltip } from '@mantine/core';
import { ChevronDown, ChevronLeft } from 'tabler-icons-react';
import { useInitialData } from '~/layouts/hooks/useInitialData';
import { lightenColor } from '~/layouts/utils';
import * as Styled from './styles';
export const SidebarButton = ({ to, title, subBtn, handleClick, icon, isOpen, setIsOpen, }) => {
    const [openSubMenu, setOpenSubMenu] = useState(false);
    const location = useLocation();
    const { data: initialData } = useInitialData();
    const handleOpenSubMenu = () => {
        if (subBtn !== undefined) {
            setOpenSubMenu(!openSubMenu);
            if (setIsOpen && !isOpen) {
                setIsOpen(true);
            }
        }
    };
    useEffect(() => {
        if (!isOpen) {
            setOpenSubMenu(false);
        }
    }, [isOpen]);
    const buttonContent = (_jsxs(Group, Object.assign({ gap: "8px", align: "center", justify: isOpen ? '' : 'center', w: "230px", wrap: "nowrap" }, { children: [icon, isOpen && (_jsx(Text, Object.assign({ weight: "700", fontSize: "14px", lineHeight: "22px", fontColor: "white" }, { children: title })))] })));
    const renderButton = () => {
        if (subBtn === undefined) {
            return (_jsx(Styled.SidebarButton, Object.assign({ to: { pathname: to }, onClick: handleClick, active: location.pathname === to ? 'true' : 'false', isOpen: isOpen }, { children: buttonContent })));
        }
        return (_jsxs(Styled.NavBtn, Object.assign({ onClick: handleOpenSubMenu }, { children: [buttonContent, isOpen &&
                    (openSubMenu ? (_jsx(ChevronDown, { size: "14px", color: "white" })) : (_jsx(ChevronLeft, { size: "14px", color: "white" })))] })));
    };
    return (_jsxs("div", { children: [isOpen ? (renderButton()) : (_jsx(Tooltip, Object.assign({ label: title, withArrow: true }, { children: renderButton() }))), subBtn !== undefined && (_jsx(Styled.SubMenu, Object.assign({ opened: openSubMenu.toString(), tall: subBtn.length }, { children: subBtn.map((btn) => (_jsx(Styled.SubLink, Object.assign({ to: btn.to, onClick: handleClick, active: location.pathname === btn.to ? 'true' : 'false', disabled: btn.soon }, { children: _jsxs("span", { children: [btn.title, ' ', btn.soon && (_jsx(Badge, Object.assign({ weight: "400", style: {
                                    backgroundColor: lightenColor(initialData === null || initialData === void 0 ? void 0 : initialData.theme.primary_color, 0.25) ||
                                        '#D5CAF2',
                                    color: '#000000',
                                    whiteSpace: 'nowrap',
                                } }, { children: "EM BREVE" })))] }) }), btn.title))) })))] }));
};
